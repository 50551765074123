var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CModal",
        {
          attrs: {
            title: "Change Password",
            color: "warning",
            show: _vm.passwordChangeModalIsOpen
          },
          on: { "update:show": _vm.updateShow },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.changePassword()
                        }
                      }
                    },
                    [_vm._v("Change")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("CInput", {
            attrs: { label: "New Password", type: "text" },
            model: {
              value: _vm.newPassword,
              callback: function($$v) {
                _vm.newPassword = $$v
              },
              expression: "newPassword"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }